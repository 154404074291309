@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply antialiased text-gray-900;
  }
}

@layer components {
  .nav-link {
    @apply text-gray-600 hover:text-gray-900 transition-colors duration-200;
  }
  
  .btn-primary {
    @apply bg-emerald-600 hover:bg-emerald-700 text-white rounded transition-colors duration-200;
  }
  
  .btn-whatsapp {
    @apply bg-whatsapp hover:bg-whatsapp-hover text-white rounded-full shadow-lg transition-colors duration-200;
  }
}